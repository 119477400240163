import React, { useState, useEffect } from 'react';
import { Modal, Paper, Typography, Grid, TextField, Button, IconButton } from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import CloseIcon from '@mui/icons-material/Close';

export default function Editar({ open, onClose, boleto, onUpdate }) {
  const [formData, setFormData] = useState({
    documento: '',
    emissao: null,
    vencimento: null,
    cliente: '',
    cpfCnpj: '',
    valor: '',
    empresa: '',
    juros: '',
    multa: '',
    protesto: '',
  });

  useEffect(() => {
    if (boleto) setFormData(boleto);
  }, [boleto]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = () => {
    onUpdate(formData);
    onClose();
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Paper sx={{ width: 500, padding: 4, mx: 'auto', my: '10%', position: 'relative' }}>
        <IconButton sx={{ position: 'absolute', top: 8, right: 8 }} onClick={onClose}><CloseIcon /></IconButton>
        <Typography variant="h6" mb={2}>Editar Boleto/Duplicata</Typography>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField fullWidth label="Documento" name="documento" value={formData.documento} onChange={handleChange} />
          </Grid>
          <Grid item xs={12}>
            <TextField fullWidth label="Cliente" name="cliente" value={formData.cliente} onChange={handleChange} />
          </Grid>
          <Grid item xs={6}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker label="Emissão" value={formData.emissao} onChange={(value) => setFormData({ ...formData, emissao: value })} renderInput={(params) => <TextField {...params} fullWidth />} />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={6}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker label="Vencimento" value={formData.vencimento} onChange={(value) => setFormData({ ...formData, vencimento: value })} renderInput={(params) => <TextField {...params} fullWidth />} />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" color="primary" fullWidth onClick={handleSubmit}>Salvar Alterações</Button>
          </Grid>
        </Grid>
      </Paper>
    </Modal>
  );
}
