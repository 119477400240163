import React, { useState } from 'react';
import { Box, Button, Typography, Snackbar, Alert } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import BoletoTable from './BoletoTable';
import BoletoModal from './BoletoModal';

export default function BoletoDuplicatas() {
  const [boletos, setBoletos] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [editingBoleto, setEditingBoleto] = useState(null);

  const handleSave = (novoBoleto) => {
    setBoletos((prev) =>
      editingBoleto
        ? prev.map((b) => (b.id === editingBoleto.id ? novoBoleto : b))
        : [...prev, { ...novoBoleto, id: Date.now() }]
    );
    
    setSnackbarMessage(editingBoleto ? 'Boleto atualizado!' : 'Boleto cadastrado!');
    setSnackbarOpen(true);
    setModalOpen(false);
    setEditingBoleto(null);
  };

  const handleEdit = (boleto) => {
    setEditingBoleto(boleto);
    setModalOpen(true);
  };

  const handleDelete = (id) => {
    setBoletos(boletos.filter((boleto) => boleto.id !== id));
    setSnackbarMessage('Boleto removido!');
    setSnackbarOpen(true);
  };

  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h6" sx={{ marginBottom: 4 }}>
        Boletos e Duplicatas
      </Typography>

      <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={() => setModalOpen(true)}>
        Novo Boleto/Duplicata
      </Button>

      <BoletoTable boletos={boletos} onEdit={handleEdit} onDelete={handleDelete} />

      <BoletoModal open={modalOpen} onClose={() => setModalOpen(false)} onSave={handleSave} boleto={editingBoleto} />

      <Snackbar open={snackbarOpen} autoHideDuration={3000} onClose={() => setSnackbarOpen(false)}>
        <Alert severity="success">{snackbarMessage}</Alert>
      </Snackbar>
    </Box>
  );
}
