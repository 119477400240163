import React from 'react';
import { Modal, Paper, Typography, Grid, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export default function Visualizar({ open, onClose, boleto }) {
  return (
    <Modal open={open} onClose={onClose}>
      <Paper sx={{ width: 400, padding: 4, mx: 'auto', my: '10%', position: 'relative' }}>
        <IconButton sx={{ position: 'absolute', top: 8, right: 8 }} onClick={onClose}><CloseIcon /></IconButton>
        <Typography variant="h6" mb={2}>Visualizar Boleto</Typography>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography><strong>Documento:</strong> {boleto.documento}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography><strong>Cliente:</strong> {boleto.cliente}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography><strong>Emissão:</strong> {boleto.emissao?.toLocaleDateString()}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography><strong>Vencimento:</strong> {boleto.vencimento?.toLocaleDateString()}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography><strong>Valor:</strong> R$ {parseFloat(boleto.valor).toFixed(2)}</Typography>
          </Grid>
        </Grid>
      </Paper>
    </Modal>
  );
}
