import React, { useState } from 'react';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import { Box, Paper, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import Editar from './Editar';
import Visualizar from './Visualizar';

export default function BoletoTable({ boletos, onUpdate, onDelete }) {
  const [modalEditar, setModalEditar] = useState(false);
  const [modalVisualizar, setModalVisualizar] = useState(false);
  const [boletoSelecionado, setBoletoSelecionado] = useState(null);

  const handleEdit = (boleto) => {
    setBoletoSelecionado(boleto);
    setModalEditar(true);
  };

  const handleView = (boleto) => {
    setBoletoSelecionado(boleto);
    setModalVisualizar(true);
  };

  const columns = [
    { field: 'documento', headerName: 'Documento', width: 130 },
    { field: 'emissao', headerName: 'Emissão', width: 130, type: 'date' },
    { field: 'vencimento', headerName: 'Vencimento', width: 130, type: 'date' },
    { field: 'cliente', headerName: 'Cliente', width: 200 },
    { 
      field: 'valor', 
      headerName: 'Valor', 
      width: 130, 
      type: 'number', 
      valueFormatter: (params) => `R$ ${parseFloat(params.value).toFixed(2)}`
    },
    { 
      field: 'acoes', 
      headerName: 'Ações', 
      width: 150,
      type: 'actions',
      getActions: (params) => [
        <GridActionsCellItem icon={<VisibilityIcon />} label="Visualizar" onClick={() => handleView(params.row)} />,
        <GridActionsCellItem icon={<EditIcon />} label="Editar" onClick={() => handleEdit(params.row)} />,
        <GridActionsCellItem icon={<DeleteIcon />} label="Excluir" onClick={() => onDelete(params.id)} sx={{ color: 'red' }} />,
      ]
    }
  ];

  const rows = boletos.map((boleto) => ({
    id: boleto.id,
    documento: boleto.documento,
    emissao: boleto.emissao ? new Date(boleto.emissao) : null,
    vencimento: boleto.vencimento ? new Date(boleto.vencimento) : null,
    cliente: boleto.cliente,
    valor: boleto.valor,
  }));

  return (
    <>
      <Box component={Paper} sx={{ height: 500, width: '100%', marginTop: 3 }}>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSizeOptions={[5, 10, 20]}
          pagination
          localeText={{
            toolbarColumns: 'Colunas',
            toolbarFilters: 'Filtros',
            toolbarDensity: 'Densidade',
            toolbarExport: 'Exportar',
            columnMenuSortAsc: 'Ordenar crescente',
            columnMenuSortDesc: 'Ordenar decrescente',
            columnMenuFilter: 'Filtrar',
            columnMenuHideColumn: 'Ocultar coluna',
            columnMenuUnsort: 'Remover ordenação',
          }}
        />
      </Box>

      {/* Modais */}
      {modalEditar && <Editar open={modalEditar} onClose={() => setModalEditar(false)} boleto={boletoSelecionado} onUpdate={onUpdate} />}
      {modalVisualizar && <Visualizar open={modalVisualizar} onClose={() => setModalVisualizar(false)} boleto={boletoSelecionado} />}
    </>
  );
}
