import React, { useEffect, useState } from 'react';
import {
  Box, Button, TextField, Grid, Typography, Paper, Modal,
  Snackbar, Alert, IconButton, Table, TableBody, TableCell,
  TableContainer, TableHead, TableRow, Toolbar, Pagination, Breadcrumbs, Link, MenuItem
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

export default function CentroDeCusto() {
  const [centros, setCentros] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [nome, setNome] = useState('');
  const [descricao, setDescricao] = useState('');
  const [empresaId, setEmpresaId] = useState('');
  const [editingCentro, setEditingCentro] = useState(null);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const apiUrl = process.env.REACT_APP_API_PHP_URL;
  console.log('API URL:', process.env.REACT_APP_API_PHP_URL);

  const navigate = useNavigate();

  // Função para buscar centros de custo com paginação
  const fetchCentros = async (pageNumber = 1) => {
    try {
      console.log('Buscando dados de:', `${apiUrl}/centro_de_custo.php?page=${pageNumber}`);
      
      const response = await axios.get(`${apiUrl}/centro_de_custo.php?page=${pageNumber}`);
      
      setCentros(response.data);
      setTotalPages(Math.ceil(response.data.length / 12)); // Ajuste conforme necessário
    } catch (error) {
      console.error('Erro ao buscar centros de custo:', error);
    }
  };
  
  useEffect(() => {
    fetchCentros(page);
  }, [page]);

  const handleSave = async () => {
    if (editingCentro) {
      await axios.put(`${apiUrl}/centro_de_custo.php`, { id: editingCentro.id, nome, descricao, empresa_id: empresaId });
      setSnackbarMessage('Centro de Custo editado com sucesso!');
    } else {
      await axios.post(`${apiUrl}/centro_de_custo.php`, { nome, descricao, empresa_id: empresaId });
      setSnackbarMessage('Centro de Custo adicionado com sucesso!');
    }
    setSnackbarOpen(true);
    setModalOpen(false);
    setNome('');
    setDescricao('');
    setEmpresaId('');
    setEditingCentro(null);
    fetchCentros(page);
  };

  const handleEdit = (centro) => {
    setEditingCentro(centro);
    setNome(centro.nome);
    setDescricao(centro.descricao);
    setEmpresaId(centro.empresa_id);
    setModalOpen(true);
  };

  const handleDelete = async (id) => {
    await axios.delete(`${apiUrl}/centro_de_custo.php`, { data: { id } });
    setSnackbarMessage('Centro de Custo removido com sucesso!');
    setSnackbarOpen(true);
    fetchCentros(page);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  return (
    <Box sx={{ padding: 3 }}>
      {/* Breadcrumb para navegação */}
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 2 }}>
        <Link underline="hover" color="inherit" onClick={() => navigate(-1)} style={{ cursor: 'pointer' }}>
          Módulo Financeiro
        </Link>
        <Typography color="text.primary">Contas Bancárias</Typography>
      </Breadcrumbs>

      <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
        <Typography variant="h6">Centro de Custo</Typography>
        <Button variant="contained" color="success" onClick={() => setModalOpen(true)}>
          Adicionar Centro de Custo
        </Button>
      </Box>

      {/* Tabela de Centros de Custo Cadastrados */}
      <TableContainer component={Paper} sx={{ mt: 3 }}>
        <Toolbar>
          <Typography variant="h6">Lista de Centros de Custo</Typography>
        </Toolbar>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Nome</TableCell>
              <TableCell>Descrição</TableCell>
              <TableCell>Empresa ID</TableCell>
              <TableCell>Ações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {centros.map((centro) => (
              <TableRow key={centro.id}>
                <TableCell>{centro.id}</TableCell>
                <TableCell>{centro.nome}</TableCell>
                <TableCell>{centro.descricao}</TableCell>
                <TableCell>{centro.empresa_id}</TableCell>
                <TableCell>
                  <IconButton color="primary" onClick={() => handleEdit(centro)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton color="error" onClick={() => handleDelete(centro.id)}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Paginação */}
      <Box display="flex" justifyContent="center" mt={2}>
        <Pagination
          count={totalPages}
          page={page}
          onChange={handlePageChange}
          color="primary"
        />
      </Box>

      {/* Modal para Adicionar/Editar Centro de Custo */}
      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <Paper
          sx={{
            width: '90%',
            maxWidth: 500,
            padding: 4,
            mx: 'auto',
            my: '5%',
            position: 'relative',
          }}
        >
          <IconButton
            aria-label="close"
            onClick={() => setModalOpen(false)}
            sx={{ position: 'absolute', top: 8, right: 8 }}
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" mb={2}>
            {editingCentro ? 'Editar Centro de Custo' : 'Adicionar Centro de Custo'}
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="Nome"
                fullWidth
                value={nome}
                onChange={(e) => setNome(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Descrição"
                fullWidth
                multiline
                rows={4}
                value={descricao}
                onChange={(e) => setDescricao(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                select
                label="Empresa"
                fullWidth
                value={empresaId}
                onChange={(e) => setEmpresaId(e.target.value)}
              >
                <MenuItem value="1">AABB Engenharia</MenuItem>
                <MenuItem value="2">Concreteira</MenuItem>
                <MenuItem value="3">Chalé</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <Button variant="contained" color="primary" fullWidth onClick={handleSave}>
                Salvar Centro de Custo
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Modal>

      {/* Snackbar para Feedback */}
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}>
        <Alert onClose={() => setSnackbarOpen(false)} severity="success">
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}
