import React, { useState, useEffect } from 'react';
import { Modal, Paper, Typography, Grid, TextField, Button, IconButton, Autocomplete } from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import CloseIcon from '@mui/icons-material/Close';

export default function BoletoModal({ open, onClose, onSave, boleto }) {
  const [clientes, setClientes] = useState([]); // Estado para armazenar a lista de clientes
  const [formData, setFormData] = useState({
    documento: '',
    emissao: null,
    vencimento: null,
    cliente: '',
    cpfCnpj: '',
    valor: '',
    empresa: '',
    juros: '',
    multa: '',
    protesto: '',
  });

  // Atualiza o formulário quando o boleto é passado como prop (edição)
  useEffect(() => {
    if (boleto) {
      setFormData(boleto);
    } else {
      resetForm();
    }
  }, [boleto]);

  // Função para lidar com mudanças nos campos de texto
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Função para lidar com mudanças nos campos de data
  const handleDateChange = (field, value) => {
    setFormData({ ...formData, [field]: value });
  };

  // Função para resetar o formulário
  const resetForm = () => {
    setFormData({
      documento: '',
      emissao: null,
      vencimento: null,
      cliente: '',
      cpfCnpj: '',
      valor: '',
      empresa: '',
      juros: '',
      multa: '',
      protesto: '',
    });
  };

  // Função para salvar o boleto
  const handleSubmit = () => {
    onSave(formData);
    resetForm();
  };

  // Função para buscar clientes na API
  const fetchClientes = async (query) => {
    if (!query || query.length < 3) return; // Só busca após 3 caracteres
  
    try {
      console.log("🔎 Buscando clientes com: ", query);
  
      const response = await fetch(`${process.env.REACT_APP_API_PHP_URL}/boletos_duplicatas.php?clientes=${query}`);
      
      if (!response.ok) {
        throw new Error(`Erro na requisição: ${response.status}`);
      }
  
      const data = await response.json();
      console.log("📋 Clientes encontrados: ", data);
  
      if (Array.isArray(data)) {
        setClientes(data); // Atualiza o estado com os clientes recebidos
      } else {
        console.error("❌ Resposta inesperada da API", data);
        setClientes([]); // Garante que não dê erro no Autocomplete
      }
    } catch (error) {
      console.error("Erro na busca de clientes:", error);
    }
  };
  

  return (
    <Modal open={open} onClose={onClose}>
      <Paper sx={{ width: 500, padding: 4, mx: 'auto', my: '10%', position: 'relative' }}>
        {/* Botão "X" para fechar o modal */}
        <IconButton sx={{ position: 'absolute', top: 8, right: 8 }} onClick={onClose}>
          <CloseIcon />
        </IconButton>

        <Typography variant="h6" mb={2}>
          {boleto ? 'Editar Boleto/Duplicata' : 'Novo Boleto/Duplicata'}
        </Typography>

        <Grid container spacing={2}>
          {/* Campo Documento */}
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Documento"
              name="documento"
              value={formData.documento}
              onChange={handleChange}
            />
          </Grid>

          {/* Campo Emissão */}
          <Grid item xs={6}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Emissão"
                value={formData.emissao}
                onChange={(value) => handleDateChange('emissao', value)}
                slots={{ textField: (params) => <TextField {...params} fullWidth /> }} // Nova sintaxe
              />
            </LocalizationProvider>
          </Grid>

          {/* Campo Vencimento */}
          <Grid item xs={6}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Vencimento"
                value={formData.vencimento}
                onChange={(value) => handleDateChange('vencimento', value)}
                slots={{ textField: (params) => <TextField {...params} fullWidth /> }} // Nova sintaxe
              />
            </LocalizationProvider>
          </Grid>

          {/* Autocomplete para buscar clientes */}
          <Grid item xs={12}>
            <Autocomplete
              options={clientes} // Usa o estado atualizado
              getOptionLabel={(option) => option.nome_completo || ""} // Garante que nunca seja "undefined"
              isOptionEqualToValue={(option, value) => option.id === value.id} 
              renderOption={(props, option) => (
                <li {...props} key={option.id}>
                  {option.nome_completo}
                </li>
              )}
              onInputChange={(event, value) => {
                console.log("✍ Digitando: ", value);
                if (value.length > 2) {
                  fetchClientes(value); // Chama a API apenas após 3 caracteres
                }
              }}
              onChange={(event, value) => {
                console.log("✅ Cliente Selecionado: ", value);
                if (value) {
                  setFormData({
                    ...formData,
                    cliente: value.nome_completo,
                  });
                }
              }}
              renderInput={(params) => (
                <TextField {...params} label="Nome do Cliente" fullWidth />
              )}
            />
          </Grid>

          {/* Campo CPF/CNPJ */}
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="CPF/CNPJ"
              name="cpfCnpj"
              value={formData.cpfCnpj}
              onChange={handleChange}
            />
          </Grid>

          {/* Campo Valor */}
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Valor"
              name="valor"
              type="number"
              value={formData.valor}
              onChange={handleChange}
            />
          </Grid>

          {/* Campo Empresa */}
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Empresa"
              name="empresa"
              value={formData.empresa}
              onChange={handleChange}
            />
          </Grid>

          {/* Campo Juros */}
          <Grid item xs={4}>
            <TextField
              fullWidth
              label="Juros (%)"
              name="juros"
              type="number"
              value={formData.juros}
              onChange={handleChange}
            />
          </Grid>

          {/* Campo Multa */}
          <Grid item xs={4}>
            <TextField
              fullWidth
              label="Multa (%)"
              name="multa"
              type="number"
              value={formData.multa}
              onChange={handleChange}
            />
          </Grid>

          {/* Campo Protesto */}
          <Grid item xs={4}>
            <TextField
              fullWidth
              label="Protesto"
              name="protesto"
              value={formData.protesto}
              onChange={handleChange}
            />
          </Grid>

          {/* Botão Salvar */}
          <Grid item xs={6}>
            <Button variant="contained" color="primary" fullWidth onClick={handleSubmit}>
              Salvar
            </Button>
          </Grid>

          {/* Botão Limpar */}
          <Grid item xs={6}>
            <Button variant="outlined" color="secondary" fullWidth onClick={resetForm}>
              Limpar
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Modal>
  );
}