import React, { useState, useMemo } from 'react';
import {
  Box,
  Grid,
  Typography,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  Button,
  TextField,
  useMediaQuery,
  Container,
  Divider,
} from '@mui/material';
import {
  AccountBalance as AccountBalanceIcon,
  Receipt as ReceiptIcon,
  AttachMoney as AttachMoneyIcon,
  AccountBalanceWallet as WalletIcon,
  BusinessCenter as BusinessIcon,
  TrendingUp as TrendingIcon,
  Payments as PaymentsIcon,
  Assessment as DREIcon,
  CalendarToday as DayIcon,
  People as ClienteIcon,
  LocalShipping as VeiculoIcon,
  Store as FornecedorIcon,
  MonetizationOn as ComissaoIcon,
  ArrowForwardIos as ArrowIcon,
} from '@mui/icons-material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

const financialModules = [
  { title: 'Boletos e Duplicatas', icon: <PaymentsIcon />, link: '/boletos-duplicatas' },
  { title: 'Caixa', icon: <WalletIcon />, link: '/caixa' },
  { title: 'Comissão', icon: <ComissaoIcon />, link: '/comissao' },
  { title: 'Conta Corrente', icon: <AccountBalanceIcon />, link: '/conta-corrente' },
  { title: 'Contas a Pagar', icon: <ReceiptIcon />, link: '/contas-pagar' },
  { title: 'Contas a Receber', icon: <AttachMoneyIcon />, link: '/contas-receber' },
  { title: 'Centro de custo', icon: <AttachMoneyIcon />, link: '/centro-custo' },

  { title: 'D.R.E', icon: <DREIcon />, link: '/dre' },
  { title: 'Fluxo de Caixa', icon: <TrendingIcon />, link: '/fluxo-caixa' },
  { title: 'Folha de pagamento', icon: <BusinessIcon />, link: '/folha-pagamento' },
  { title: 'Gerencial', icon: <DREIcon />, link: '/gerencial' },
  { title: 'Meu Cliente', icon: <ClienteIcon />, link: '/meu-cliente' },
  { title: 'Meu Dia', icon: <DayIcon />, link: '/meu-dia' },
  { title: 'Meu Fornecedor', icon: <FornecedorIcon />, link: '/meu-fornecedor' },
  { title: 'Meu Veículo', icon: <VeiculoIcon />, link: '/meu-veiculo' },
  { title: 'Minha Comissão', icon: <ComissaoIcon />, link: '/minha-comissao' },
];

export default function ModuloFinanceiro() {
  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: 'light',
          primary: { main: '#1E4A7B' },
          secondary: { main: '#546E7A' },
          background: { default: '#F3F6F9', paper: '#FFFFFF' },
        },
        typography: { fontFamily: 'Poppins, sans-serif', fontSize: 14 },
      }),
    []
  );

  const [searchTerm, setSearchTerm] = useState('');
  const filteredModules = financialModules.filter((module) =>
    module.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          padding: isSmallScreen ? 2 : 4,
          minHeight: '100vh',
          background: theme.palette.background.default,
        }}
      >
        {/* Header */}
        <Container maxWidth="lg" sx={{ textAlign: 'center', marginBottom: 4 }}>
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
            <Typography variant="h4" sx={{ fontWeight: 'bold', color: theme.palette.primary.main }}>
              Módulo Financeiro
            </Typography>
            <Typography variant="subtitle1" sx={{ color: theme.palette.secondary.main, marginTop: 1 }}>
              Ferramentas essenciais para o controle financeiro do seu negócio.
            </Typography>
          </motion.div>
        </Container>

        {/* Campo de Busca */}
        <Container maxWidth="lg" sx={{ marginBottom: 4 }}>
          <TextField
            label="Buscar Módulo"
            variant="outlined"
            fullWidth
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </Container>

        <Divider sx={{ marginY: 4 }} />

        {/* Lista de Módulos em 2 Colunas */}
        <Container maxWidth="lg">
          <Grid container spacing={2}>
            {filteredModules.map((module, index) => (
              <Grid item xs={12} sm={6} key={index}>
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: index * 0.1, duration: 0.5 }}
                >
                  <ListItem
                    button
                    component={Link}
                    to={module.link}
                    sx={{
                      borderRadius: 2,
                      boxShadow: 1,
                      marginBottom: 2,
                      '&:hover': {
                        boxShadow: 3,
                        transform: 'translateX(5px)',
                      },
                    }}
                  >
                    <ListItemAvatar>
                      <Avatar sx={{ bgcolor: theme.palette.primary.main }}>{module.icon}</Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={module.title}
                      primaryTypographyProps={{ fontWeight: 500 }}
                    />
                    <ArrowIcon />
                  </ListItem>
                </motion.div>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
    </ThemeProvider>
  );
}
